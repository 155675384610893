.App {
  text-align: center;
  height: 100%;
}

.App {
  background-image: url('./assets/img/clap.jpg');  /* Ruta de la imagen */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.App-logo {
  height: 400px;
  pointer-events: none;
}

code {
  font-size: calc(15px + 2vmin);
  color: white;
  font-weight: 800;
}

.Linktree {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Pantallas medianas: entre 992px y 1200px */
@media screen and (max-width: 1424px){
  .Linktree {
    margin-top: 3em;
  }
  .card {
    margin-bottom: 4em;
  }
  .card a p{
    font-size: xx-large;
  }
  .App-logo {
    height: 400px;
  }
}

@media screen and (min-width:1445px) {
  .card {
    margin-bottom: 1em;
  }
  .card a p {
    font-size: medium;
  }
  .App-logo {
    height: 200px;
  }
}



.card {
  text-align: center;
  border-radius: 10px;
  width: 60%;
  border: 5px solid;
  transition-duration: 0.5s;
  text-decoration: none;
  font-weight: 700;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.card:hover {
  width:50%;
  transition-duration: 0.5s;
}

.card p, .card a {
  color: white;
  text-decoration: none;
  font-size: medium;
}

.SPOTIFY:hover {
  background-color: rgb(0, 194, 0);
}

.SOUNDCLOUD:hover {
  background-color: rgb(226, 90, 0);
}

.APPLEMUSIC:hover {
  background-color: rgb(207, 0, 207);
}

.BANDCAMP:hover {
  background-color: rgb(0, 97, 207);
}

.TWITCH:hover {
  background-color: rgb(110, 0, 138);
}

.YOUTUBE:hover {
  background-color: rgb(255, 0, 0);
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */